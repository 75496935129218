import { Fragment } from "react";
import { useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import gfcLogo from "@/images/logos/gfc-logo.png";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function Header({ mobileTitle = "" }) {
  const router = useRouter();
  const navigation = [
    { name: "About", href: "/about", className: "nav-about" },
    // { name: "Issues", href: "/issues", className: "nav-issues" },
    // {
    //   name: "Why You Should Care",
    //   href: "/why-you-should-care",
    //   className: "nav-why-you-should-care",
    // },
    { name: "News", href: "/news", className: "nav-news" },
    { name: "Donate", href: "/donate", className: "nav-donate" },
  ];
  useEffect(() => {
    // set active navigation link color
    let currClass = router.asPath.slice(1); // remove leading /
    if (currClass.startsWith("news")) {
      currClass = "news";
    } else if (currClass.startsWith("donate")) {
      currClass = "donate";
    }
    const currLinks = document.getElementsByClassName(`nav-${currClass}`);
    for (let i = 0; i < currLinks.length; i++) {
      currLinks[i].classList.add("text-brand-text");
      currLinks[i].classList.remove("text-gray-900");
    }
  }, [router.asPath]);
  return (
    <Popover as="header" className="relative">
      <div className="bg-white pt-3">
        <nav
          className="relative mx-auto flex max-w-7xl items-center justify-between border-b-[1px] border-b-gray-100 px-4 pb-3 sm:px-6"
          aria-label="Global"
        >
          <div className="flex flex-1 items-center">
            <div className="flex w-full flex-row items-center  md:w-auto">
              <Link href="/" className="basis-1/5 md:basis-full">
                <span className="sr-only">Govern For California</span>
                <Image
                  priority={true}
                  className="h-12 w-auto md:h-16"
                  src={gfcLogo}
                  alt="Govern For California Logo"
                />
              </Link>
              <div className="basis-3/5 text-center text-lg font-medium md:hidden">
                {mobileTitle}
              </div>
              <div className="-mr-2 flex basis-1/5  items-center justify-end md:hidden">
                <Popover.Button className="inline-flex items-center justify-center rounded-md bg-gray-50 p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand-button">
                  <span className="sr-only">Open main menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="hidden space-x-8 md:ml-10 md:flex">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  href={item.href}
                  className={classNames(
                    item.className,
                    "text-base font-medium hover:text-brand-text",
                  )}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
        </nav>
      </div>

      <Transition
        as={Fragment}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute inset-x-0 top-0 z-10 origin-top transform p-2 transition md:hidden"
        >
          <div className="overflow-hidden rounded-lg border-[1px] border-gray-300 bg-white shadow-xl ring-1 ring-brand-button ring-opacity-5">
            <div className="flex items-center justify-between px-5 pt-4">
              <div>
                <Link href="/">
                  {/* <Image
                    priority={true}
                    className="h-10 w-auto"
                    src={gfcLogo}
                    alt="Govern For California Logo"
                  /> */}
                  <h1 className="mt-2 text-[1.5rem] font-bold tracking-tighter text-gray-900 drop-shadow-sm sm:mt-2 sm:text-5xl lg:mt-2 lg:text-[2.9rem] xl:text-6xl">
                    Govern For&nbsp;
                    <span className="bg-gradient-to-r from-brand-light to-brand-text bg-clip-text pb-3 text-transparent drop-shadow-sm sm:pb-5">
                      California
                    </span>
                  </h1>
                </Link>
              </div>
              <div className="-mr-2">
                <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand-button">
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="pb-6 pt-5">
              <div className="space-y-1 px-2">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.className,
                      "block rounded-md px-3 py-2 text-base font-medium hover:text-brand-text",
                    )}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
