import Image from "next/image";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import { Button } from "@/components/Button";
import goldenGateBridge from "@/images/golden-gate-bridge.jpg";

export function CallToAction(props) {
  return (
    <section className={props.className}>
      <div className="relative bg-gray-900">
        <div className="relative h-56 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
          <Image
            className="h-full w-full object-cover"
            src={goldenGateBridge}
            alt="Golden Gate Bridge, San Francisco, California"
          />
        </div>
        <div className="relative mx-auto px-4 py-12 sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
          <div className="md:ml-auto md:w-1/2 md:pl-10">
            <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">
              Mission
            </h2>
            {/* <h2 className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
              How You Can Help
            </h2> */}
            <p className="mt-3 text-lg text-gray-300">
              To counter special interest influence and to support like-minded
              organizations.
            </p>
            {/* <h2 className="mt-10 text-base font-semibold uppercase tracking-wider text-gray-300">
              Get Involved
            </h2>
            <p className="mt-3 text-lg text-gray-300">
              <a
                href="https://action.governforcalifornia.org/a/subscribe"
                className="body-link"
                target="blank"
                rel="noreferrer"
              >
                Subscribe
              </a>
              <ExternalLinkIcon
                className="ml-[3px] inline h-3"
                aria-hidden="true"
              />
              &nbsp;to GFC updates and consider donating to support our work.
            </p> */}
            <div className="mt-8">
              <Button href="/donate" className="border border-gray-500">
                Donate
              </Button>
              <Button
                href="https://action.governforcalifornia.org/a/subscribe"
                target="_blank"
                rel="noreferrer"
                className="ml-3 border border-gray-500"
              >
                <span className="inline-flex items-center">
                  Subscribe
                  <ExternalLinkIcon
                    className="-mr-1 ml-[3px] h-3"
                    aria-hidden="true"
                  />
                </span>
              </Button>
              {/* <Button href="/" className="ml-3 border border-gray-500">
                FAQ
              </Button> */}
              {/* <Button
                href="/who-we-are"
                className="ml-3 border border-gray-500"
              >
                Who we are
              </Button> */}
              {/* <Button
                href="https://drive.google.com/file/d/1ouluSJGsA9yD-zPVYR4OMqrT5R6VGeHl/view?usp=sharing"
                target="_blank"
                rel="noreferrer"
                className="ml-3 border border-gray-500"
              >
                <span className="inline-flex items-center">
                  Get one-pager
                  <ExternalLinkIcon
                    className="-mr-1 ml-[3px] h-3"
                    aria-hidden="true"
                  />
                </span>
              </Button> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
